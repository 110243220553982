import React from "react"
// import { Link } from "gatsby"
import { connect, useSelector } from "react-redux"

import { PrivacyPolicyQuery } from "../../prismic/staticQueries"
import Layout from "../../components/layout"
import ContentBasic from "../../components/content-basic"
// import { IMAGES } from '../../constants';

const PrivacyPolicy = () => {
  const language = useSelector(state => state.language)
  const privacyPageData = PrivacyPolicyQuery(language)
  return (
    <>
      <Layout>
        <ContentBasic {...privacyPageData} />
      </Layout>
    </>
  )
}

const mapStateToProps = state => {
  return {
    language: state.language,
  }
}

export default connect(mapStateToProps)(PrivacyPolicy)
